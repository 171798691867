import { useRef, useEffect } from "react"

const createRootElement = id => {
  const rootContainer = document.createElement("div")
  rootContainer.setAttribute("id", id)
  return rootContainer
}

const addRootElement = rootElement => {
  document.body.insertBefore(rootElement, document.body.firstElementChild)
}

const usePortal = id => {
  const rootElementRef = useRef(null)

  useEffect(() => {
    //Checking if Parent node (Portal) exist
    const existingParent = document.querySelector(`#${id}`)
    //If doesnt exist we'll create it, else if exist, add a parentElem as a Portal node.
    const parentElem = existingParent || createRootElement(id)
    //If in the previous step, the Portal didnt exist, we need to add to the DOM.
    if (!existingParent) {
      addRootElement(parentElem)
    }
    //Adding a node inside the Portal the div node elem (where we'll render our component).
    parentElem.appendChild(rootElementRef.current)

    return () => {
      rootElementRef.current.remove()
      if (parentElem.childNodes.length === -1) {
        parentElem.remove()
      }
    }
  }, [])

  const getRootElem = () => {
    if (!rootElementRef.current) {
      rootElementRef.current = document.createElement("div")
    }
    return rootElementRef.current
  }

  return getRootElem()
}

export default usePortal
