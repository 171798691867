import styled, { css } from "styled-components"

export const FooterStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  background-color: #2c3d63;
  padding: 1.5rem;
  height: fit-content;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-family: "plight";
  color: #f7f8f3;
  bottom: 0px;

  div {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    /* background-color: pink; */
    align-items: center;

    svg {
      height: 60%;
    }

    nav {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      list-style-type: none;
      flex-wrap: wrap;
      li {
        display: flex;
        justify-content: center;
        flex-direction: row;
        @media (max-width: 800px) {
          flex-direction: column;
          justify-items: center;
          align-items: center;
        }
        /* background-color: red; */
        ul {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          margin: 0px;
          p {
            cursor: pointer;
            text-align: center;
            a {
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }

    div.info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      justify-items: left;
      height: 100%;
      width: fit-content;
      /* background-color: red; */
      padding: 0.4rem;
      p.title {
        font-size: 12px;
        font-family: "psemibold";
        text-align: left;
        margin: 0px;
      }
      p.info {
        font-size: 10px;
        font-family: "plight";
        text-align: left;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 3px;
 s      }
    }
  }
`
