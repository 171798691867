import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const HeaderNav = styled(motion.div)`
  /* background-color: #addcca; */
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;

  nav {
    list-style-type: none;
  }

  svg {
    height: 6rem;
    width: 6rem;
    color: #2c3d63;
  }

  nav li {
    display: flex;
    flex-direction: row;
  }

  nav li ul {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0px;
    font-family: "pmedium", sans-serif;
    color: #2c3d63;
    font-size: 0.9rem;
    p {
      a {
        color: #2c3d63;
        cursor: pointer;
      }
    }
    /* background-color: green; */
  }

  nav li ul p {
    cursor: pointer;
  }

  @media (max-width: 800px) {
    justify-content: center;
    nav {
      background-color: green;
      display: none;
    }
  }
`
