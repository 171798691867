import React, { useEffect, useRef } from "react"
import Lottie from "lottie-web"
import animationData from "../assets/icons/burguer.json"

import { ButtonStyle } from "../styles/MenuStyle"

let animObject = null

const BurguerButton = ({ openedMenu, setOpenedMenu }) => {
  const animationDiv = useRef(null)

  useEffect(() => {
    animObject = Lottie.loadAnimation({
      container: animationDiv.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData,
    })
  }, [])

  const playOpenAnimation = () => {
    animObject.playSegments([20, 90], true)
    setOpenedMenu(true)
  }

  const playCloseAnimation = () => {
    animObject.playSegments([110, 180], true)
    setOpenedMenu(false)
  }

  return (
    <>
      <ButtonStyle
        openedMenu={openedMenu}
        onClick={() => {
          openedMenu ? playCloseAnimation() : playOpenAnimation()
        }}
        ref={animationDiv}
      ></ButtonStyle>
    </>
  )
}

export default BurguerButton
