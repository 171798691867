import React from "react"
import { FooterStyle } from "../styles/FooterStyle"
//SVG'S
import LogoPowder from "../assets/icons/logoPowder.svg"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <FooterStyle>
      <div>
        <LogoPowder />
        <nav>
          <li>
            <ul>
              <p>
                <Link to="/">Inicio</Link>
              </p>
            </ul>
            <ul>
              <p>
                <Link to="/cursos">Cursos y precios</Link>
              </p>
            </ul>
            <ul>
              <p>
                <Link to="/nosotros">Nosotros</Link>
              </p>
            </ul>
            <ul>
              <p>
                <Link to="/contacto">Contacto</Link>
              </p>
            </ul>
            <ul>
              <p>
                <Link to="/covid">COVID-19</Link>
              </p>
            </ul>
          </li>
        </nav>
        <div className="info">
          <p className="title">Dirección</p>
          <p className="info">C/ Cervantes 19</p>
          <p className="title">Teléfono</p>
          <p className="info">639-662-840</p>
        </div>
      </div>
    </FooterStyle>
  )
}

export default Footer
