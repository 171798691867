import PopinsThin from "../assets/fonts/Poppins-Thin.ttf"
import PopinsSemibold from "../assets/fonts/Poppins-SemiBold.ttf"
import PopinsRegular from "../assets/fonts/Poppins-Regular.ttf"
import PopinsMedium from "../assets/fonts/Poppins-Medium.ttf"
import PopinsLight from "../assets/fonts/Poppins-Light.ttf"
import PopinsItalic from "../assets/fonts/Poppins-Thin.ttf"
import PopinsExtralight from "../assets/fonts/Poppins-Extralight.ttf"
import PopinsExtrabold from "../assets/fonts/Poppins-ExtraBold.ttf"
import PopinsBold from "../assets/fonts/Poppins-Bold.ttf"
import PopinsBlack from "../assets/fonts/Poppins-Black.ttf"

export {
  PopinsBlack,
  PopinsThin,
  PopinsSemibold,
  PopinsRegular,
  PopinsMedium,
  PopinsLight,
  PopinsItalic,
  PopinsExtralight,
  PopinsExtrabold,
  PopinsBold,
}
