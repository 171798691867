import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const detailsQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
        image
        url
      }
    }
  }
`

const SEO = ({ description, keywords, title, image, url }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords || [
          "academia",
          "inglés",
          "villena",
          "london",
          "school",
          "english",
          "cambridge",
          "oficial",
          "exámen",
          "academia inglés villena",
          "cambridge exams",
        ]
        //<meta name="google-site-verification" content="fGf5ZzmR2fWcHO-VF0_03ECxmj2pUiyeNJOqhWuGqEo" />
        return (
          <Helmet
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: metaTitle,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                name: `google-site-verification`,
                content: `fGf5ZzmR2fWcHO-VF0_03ECxmj2pUiyeNJOqhWuGqEo`,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          />
        )
      }}
    />
  )
}

export default SEO
