import styled, { css } from "styled-components"

export const Button = styled.button`
  font-size: 1em;
  margin: 0.8em;
  padding: 0.45em 1em;
  border: 2px solid #ff6f5e;
  border-radius: 9px;
  font-family: "pmedium";
  min-width: fit-content;
  max-width: fit-content;
  cursor: pointer;
  white-space: nowrap;

  
  
  ${props =>
    props.noMarginLeft &&
    css`
      margin-left: 0px;
    `}
  ${props =>
    props.rounded &&
    css`
      border-radius: 2000px;
    `}
 

  ${props =>
    props.noMarginRight &&
    css`
      margin-right: 0px;
    `}
  ${props =>
    props.noMargin &&
    css`
      margin: 0px;
    `}

  ${props => {
    if (props.primary) {
      return css`
        background-color: #ff6f5e;
        color: white;
      `
    } else if (props.secondary) {
      return css`
        background-color: transparent;
        color: #ff6f5e;
      `
    } else if (props.info) {
      return css`
        color: #2c3d63;
        border: 2px solid #2c3d63;
        background-color: transparent;
        font-size: 25px;
        font-family: "psemibold";
      `
    }
  }};
  
  a{
    ${props => {
      return props.primary
        ? css`
            color: white;
          `
        : css`
            color: #ff6f5e;
          `
    }}
}
`

export const Title = styled.div`
  h2 {
    font-family: "pbold", sans-serif;
    font-size: 2.1rem;
    color: #2c3d63;
    text-align: center;
  }

  h3 {
    font-family: "plight";
    font-size: 12px;
    text-align: left;
    max-width: 90%;
    color: #2c3d63;
    text-align: center;
    width: 100%;
    margin: auto;
  }
`
