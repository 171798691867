import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useViewportScroll, useTransform, useSpring } from "framer-motion"

import Portal from "../HOC/Portal"
import Menu from "./Menu.js"
import BurgerButton from "../components/BurguerButton.js"
import { HeaderNav } from "../styles/HeaderStyle"
import Logo from "../assets/icons/Logonuevo.svg"

const Header = () => {
  const [openedMenu, setOpenedMenu] = useState(false)

  return (
    <HeaderNav>
      <Logo fill="#2c3d63"></Logo>
      <nav>
        <li>
          <ul>
            <p>
              <Link to="/" activeStyle={{ borderBottom: "2px solid #2c3d63" }}>
                Inicio
              </Link>
            </p>
          </ul>
          <ul>
            <p>
              <Link
                to="/cursos"
                activeStyle={{ borderBottom: "2px solid #2c3d63" }}
              >
                Cursos y precios
              </Link>
            </p>
          </ul>
          <ul>
            <p>
              <Link
                to="/nosotros"
                activeStyle={{ borderBottom: "2px solid #2c3d63" }}
              >
                Nosotros
              </Link>
            </p>
          </ul>
          <ul>
            <p>
              <Link
                to="/contacto"
                activeStyle={{ borderBottom: "2px solid #2c3d63" }}
              >
                Contacto
              </Link>
            </p>
          </ul>
          <ul>
            <p>
              <Link
                to="/covid"
                activeStyle={{ borderBottom: "2px solid #2c3d63" }}
              >
                COVID-19
              </Link>
            </p>
          </ul>
        </li>
      </nav>

      <BurgerButton
        openedMenu={openedMenu}
        setOpenedMenu={setOpenedMenu}
      ></BurgerButton>
      {openedMenu && (
        <Portal id="Menu" openedMenu={openedMenu} setOpenedMenu={setOpenedMenu}>
          <Menu></Menu>
        </Portal>
      )}
    </HeaderNav>
  )
}

export default Header
