import React from "react"
import { MenuStyle } from "../styles/MenuStyle"
import { Link } from "gatsby"

const Menu = () => {
  return (
    <>
      <MenuStyle>
        <nav>
          <li>
            <ul>
              <Link to="/" activeStyle={{ borderBottom: "2px solid white" }}>
                Inicio
              </Link>
            </ul>
            <ul>
              <Link
                to="/cursos"
                activeStyle={{ borderBottom: "2px solid white" }}
              >
                Cursos y precios
              </Link>
            </ul>
            <ul>
              <Link
                to="/nosotros"
                activeStyle={{ borderBottom: "2px solid white" }}
              >
                Nosotros
              </Link>
            </ul>
            <ul>
              <Link
                to="/contacto"
                activeStyle={{ borderBottom: "2px solid white" }}
              >
                Contacto
              </Link>
            </ul>
            <ul>
              <Link
                to="/covid"
                activeStyle={{ borderBottom: "2px solid white" }}
              >
                COVID-19
              </Link>
            </ul>
          </li>
        </nav>
      </MenuStyle>
    </>
  )
}

export default Menu
