import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const MenuStyle = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 3rem;
  padding-top: 120px;
  background-color: #2c3d63;
  z-index: 100;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style-type: none;

      ul {
        padding: 0px;
        margin-top: 2rem;
        margin-bottom: 2rem;
        a {
          color: white;
          font-family: "pregular";
          font-size: 20px;
        }
      }
    }
  }
`

export const ButtonStyle = styled.div`
  display: none;
  position: absolute;
  right: 3rem;
  height: 30px;
  width: 30px;
  cursor: pointer;
  fill: blue;
  z-index: 150;
  ${props =>
    props.openedMenu &&
    css`
      position: fixed;
    `}
  @media (max-width: 800px) {
    display: flex;
  }
`
